import Swiper, { Pagination, Autoplay, Navigation } from 'swiper/core';
let latestSlide = undefined;
let projectsSlide = undefined;

function setupLatestSlide() {
  latestSlide = new Swiper('.js-latest-slide', {
    modules: [Pagination],
    loop: true,
    autoHeight: true,
    createElements: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });
  return latestSlide;
}

function setupProjectsSlide() {
  projectsSlide = new Swiper('.js-projects-slide', {
    modules: [Pagination],
    loop: true,
    autoHeight: false,
    createElements: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });
  return projectsSlide;
}

export default {
  breakpointMD: window.matchMedia('(min-width: 1024px)'),
  setupSuperMenu: () => {
    document.querySelectorAll('.js-menu-toggle').forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        el.classList.toggle('is-active');
        document.body.classList.toggle('is-open-menu');
        document.querySelector('.js-menu').classList.toggle('is-open');
      });
    });
  },
  setupHero: () => {
    const swiper = new Swiper('.js-hero', {
      modules: [Autoplay],
      loop: true,
      autoHeight: false,
      createElements: true,
      pagination: false,
      navigation: false,
      autoplay: {
        delay: 5000,
      },
    });

    return swiper;
  },
  breakpointChecker: (e) => {
    if (e.matches) {
      if (latestSlide !== undefined) {
        latestSlide.loopDestroy();
        latestSlide.destroy(true, true);
      }

      if (projectsSlide !== undefined) {
        projectsSlide.loopDestroy();
        projectsSlide.destroy(true, true);
      }
    } else if (e.matches == false) {
      setupLatestSlide();
      setupProjectsSlide();
    }
  },
  setupCatsFilter: () => {
    if (document.querySelector('.js-categories-filter')) {
      document.querySelector('.js-categories-filter').addEventListener('change', (e) => {
        location.href = e.currentTarget.value;
      })
    }
  },
  // eslint-disable-next-line no-unused-vars
  setupGallerySlider() {
    const swiper = new Swiper('.js-gallery-slider', {
      modules: [Navigation],
      loop: true,
      autoHeight: true,
      createElements: true,
      navigation: true,
    });

    return swiper;
  },
  init() {
    // JavaScript to be fired on all pages
    this.setupSuperMenu();
    window.matchMedia('(min-width: 1024px)').addEventListener('change', this.breakpointChecker);
    this.breakpointChecker(this);
    this.setupCatsFilter();
    this.setupHero();
    this.setupGallerySlider();
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
